import "./RSVP.css";
import React, { useRef, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { Link } from "react-router-dom";

const RSVPForm = styled.form`
  max-width: 600px;
  margin: 0px auto;
  padding: 30px;
  font-family: "Garamond", serif;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  &:focus {
    outline: none;
    border-color: #a98467;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const SubmitButton = styled.button`
  width: 50%;
  letter-spacing: 5px;
  padding: 12px;
  background-color: rgb(123, 109, 109);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: "TheSeasonsRegular", serif;
  &:hover {
    background-color: rgb(141, 127, 127);
  }
  &:disabled {
    background-color: rgb(200, 200, 200); /* Gray out the button */
    cursor: not-allowed; /* Change cursor to not-allowed */
  }
`;

const RSVPComponent = () => {
  const [message, setMessage] = useState("");
  const [attendingCount, setAttendingCount] = useState(0);
  const [notAttendingCount, setNotAttendingCount] = useState(0);
  const [guests, setGuests] = useState({ attending: [], notAttending: [] });
  const songRequestRef = useRef("");

  const handleIncrement = (type) => {
    if (type === "attending") {
      setAttendingCount(attendingCount + 1);
      setGuests({
        ...guests,
        attending: [...guests.attending, { firstName: "", lastName: "" }],
      });
    } else if (type === "not-attending") {
      setNotAttendingCount(notAttendingCount + 1);
      setGuests({
        ...guests,
        notAttending: [...guests.notAttending, { firstName: "", lastName: "" }],
      });
    }
  };

  const handleDecrement = (type) => {
    if (type === "attending" && attendingCount > 0) {
      setAttendingCount(attendingCount - 1);
      setGuests({
        ...guests,
        attending: guests.attending.slice(0, -1),
      });
    } else if (type === "not-attending" && notAttendingCount > 0) {
      setNotAttendingCount(notAttendingCount - 1);
      setGuests({
        ...guests,
        notAttending: guests.notAttending.slice(0, -1),
      });
    }
  };

  const handleInputChange = (e, index, type) => {
    const { name, value } = e.target;
    const updatedGuests = [...guests[type]];
    updatedGuests[index][name] = value;
    setGuests({
      ...guests,
      [type]: updatedGuests,
    });
  };

  const renderGuestInputs = (count, type) => {
    type = type === "not-attending" ? "notAttending" : type;
    const guestInputs = [];
    for (let i = 0; i < count; i++) {
      guestInputs.push(
        <div key={`${type}-guest-${i}`} className="guest">
          <Input
            type="text"
            placeholder="First Name"
            name="firstName"
            value={guests[type][i]?.firstName || ""}
            onChange={(e) => handleInputChange(e, i, type)}
          />
          <Input
            type="text"
            placeholder="Last Name"
            name="lastName"
            value={guests[type][i]?.lastName || ""}
            onChange={(e) => handleInputChange(e, i, type)}
          />
        </div>
      );
    }
    return guestInputs;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const attending = guests.attending;
    const notAttending = guests.notAttending;
    const songRequest = songRequestRef.current
      ? songRequestRef.current.value
      : "";
    try {
      const response = await axios.post(
        "https://t35jdxhizl.execute-api.us-east-1.amazonaws.com/default/myWeddingEmail",
        { attending, notAttending, songRequest }
      );
      console.log("message sent");
      setMessage(response.data.message);
      setAttendingCount(0);
      setNotAttendingCount(0);
      setGuests({ attending: [], notAttending: [] });
    } catch (error) {
      setMessage("An error occurred. Please try again.");
      console.error("Error submitting form:", error);
    }
  };

  return (
    <main className="rsvp-container">
      <Link className="home-link" to="/">
        take me to the main wedding site
      </Link>
      <div className="pew-container text-center">
        <div className="pew">
          <h1 className="garamond rsvp-title">RSVP</h1>
          <div className="garamond-it p-4" id="rsvp-note">
            Please reference the RSVP card in your invitation to see the number
            of guests in your party and kindly select below how many will be
            attending.
          </div>
          <RSVPForm onSubmit={handleSubmit}>
            <div className="counter-container">
              <div className="counter">
                <h3 className="garamond ls-4">WILL ATTEND</h3>
                <div className="button-group">
                  <button
                    onClick={() => handleDecrement("attending")}
                    className="button"
                    type="button"
                  >
                    -
                  </button>
                  <span className="counter-display">{attendingCount}</span>
                  <button
                    onClick={() => handleIncrement("attending")}
                    className="button"
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
              <div id="attending-guests" className="guest-list">
                {renderGuestInputs(attendingCount, "attending")}
              </div>
              <div className="counter">
                <h3 className="garamond ls-4">WILL NOT ATTEND</h3>
                <div className="button-group">
                  <button
                    onClick={() => handleDecrement("not-attending")}
                    className="button"
                    type="button"
                  >
                    -
                  </button>
                  <span className="counter-display">{notAttendingCount}</span>
                  <button
                    onClick={() => handleIncrement("not-attending")}
                    className="button"
                    type="button"
                  >
                    +
                  </button>
                </div>
              </div>
            </div>
            <div id="not-attending-guests" className="guest-list">
              {renderGuestInputs(notAttendingCount, "not-attending")}
            </div>
            {attendingCount > 0 && (
              <Input
                type="text"
                placeholder="What Song Will Get You On The Dance Floor?"
                name="song"
                ref={songRequestRef}
              />
            )}
            {/* <Input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Your Name"
                  required
                />
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email"
                  required
                /> */}
            <ButtonGroup>
              <SubmitButton
                type="submit"
                disabled={
                  (guests.attending.length === 0 &&
                    guests.notAttending.length === 0) ||
                  guests.attending.some(
                    (guest) =>
                      guest.firstName.trim() === "" ||
                      guest.lastName.trim() === ""
                  ) ||
                  guests.notAttending.some(
                    (guest) =>
                      guest.firstName.trim() === "" ||
                      guest.lastName.trim() === ""
                  )
                }
              >
                SUBMIT
              </SubmitButton>
            </ButtonGroup>
            <ButtonGroup>
              {message && (
                <p className="garamond-it mt-4" id="success-message">
                  {message}
                </p>
              )}
            </ButtonGroup>
          </RSVPForm>
        </div>
      </div>
    </main>
  );
};

export default RSVPComponent;
